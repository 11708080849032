.segmentMenuIcon {
    position: absolute;
    z-index: 100;
}

.segmentMenuIcon > :hover {
    color: #466CA0;
    cursor: pointer;
}

.segmentMenuContainer {
    padding: 10px;
    background-color: #424344ec;
    position: absolute;
    z-index: 1000;
    left: 10px;
    overflow-y: hidden;
    max-height: 320px;
    border-radius: 15px;
}

.segmentMenuHeader {
    margin-top: -8px;
    margin-left: -12px;
    color: #CBCED0;
    font-size: 14px;
    height: 25px;
}

.segmentMenuHeader>p {
    margin-top: 3px;
    font-weight: bold;
    font-size: 14px;
}

segmentMenuBody {
    max-height: 270px;
    overflow-y: auto;
    padding-bottom: 10px;
}

segmentMenuBody input[type="checkbox"] {
    margin-right: 10px;
}

.segmentName {
    color: #F9FCFF;
    font-size: 14px;
    text-align: left;
    flex: 1;
}
.noModelMessage {
    margin-top: 4px;
    color: #007bff;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
}
